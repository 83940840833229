/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
Swal = require('sweetalert2')

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});

$(document).ready(function(){
  $('.c-socials-share__list').on('click','li', function () {
    $('div[data-network="'+$(this).data('network')+'"]').click()
  })
  if(window.location.hash === '#download-encarte') {
     $('.download-encarte')[0].click();
  }

  $("[type=file]").on("change", function(){
    // Name of file and placeholder
    var file = this.files[0].name;
    var dflt = 'Selecione um arquivo';
    if($(this).val()!=""){
      $('#resume-label').text(file);
    } else {
      $('#resume-label').text(dflt);
    }
  });

});
